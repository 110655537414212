module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"titleTemplate":"%s | SCR","language":"en","openGraph":{"type":"website","locale":"en_US","url":"https://www.silvercreekrange.com/","site_name":"Silver Creek Range","images":[{"url":"https://www.silvercreekrange.com/scr-webbanner.jpg","width":1200,"height":627,"alt":"Silver Creek Range | Mobile AL's Premier Shooting Range"}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.0.29","publicApiKey":"ODUwNWI3MTItZTdjMS00ODA2LThkOGMtNTllYzExZTBlMTM2NjM3ODgzMTU0NjY0NTkwOTE4","defaultLang":"en","currency":"usd","openCartOnAdd":true,"useSideCart":false,"locales":{"en":{"cart_summary":{"taxes":"Processing Fee"}}},"innerHTML":"\n        <address-fields section=\"top\">\n            <div>\n                <snipcart-label for=\"phone\">\n                    Phone number\n                </snipcart-label>\n                <snipcart-input name=\"phone\">\n                </snipcart-input>\n            </div>\n        </address-fields>\n        <billing section=\"bottom\">\n            <fieldset class=\"snipcart-form__set\">\n                <div class=\"snipcart-form__field\">\n                    <snipcart-label class=\"snipcart__font--tiny\" for=\"comments\">\n                        Order Comments\n                    </snipcart-label>\n                    <snipcart-input name=\"comments\"></snipcart-input>\n                </div>\n            </fieldset>\n        </billing>"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Silver Creek Range","short_name":"Silver Creek Range","start_url":"/","background_color":"#F9F2F4","theme_color":"#E21A51","display":"minimal-ui","icon":"src/images/logos/scr-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5481c20635b102c9ae4a9eaf32a91344"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["AW-697247082","G-DXEZMH421V"],"pluginConfig":{"head":false,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
